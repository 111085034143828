<script setup lang="ts"></script>

<template>
  <UContainer
    :ui="{
      constrained: 'max-w-7xl lg:max-w-full 2xl:max-w-screen-2xl',
      padding: 'px-0 lg:px-0',
    }"
  >
    <slot />
  </UContainer>
</template>

<style scoped></style>
